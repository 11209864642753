
<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">{{$t('message.add_bunk')}}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <!-- <el-button type="primary">Сохранить</el-button> -->
               <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{$t('message.save_and_close')}}</el-button>
              <el-button @click="close()">{{$t('message.close')}}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form">
      <el-form ref="form" :model="form" :rules="rules" class="aticler_m" size="small">
          <el-row :gutter="25">
          <el-col :span="12">
             <el-form-item :label="$t('message.corps')" prop="corps_id">
                  <corpses v-model="form.corps_id"  :corps_id="form.corps_id" size="mini"></corpses>
              </el-form-item>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
             <el-form-item :label="$t('message.floor')" prop="floor_id">
                  <floors v-model="form.floor_id" :corps_id="form.corps_id" size="mini"></floors>
              </el-form-item>
          </el-col>
          <!-- end col -->
           <el-col :span="12">
              <el-form-item :label="$t('message.room')" prop="hospital_room_id">
                  <hospitalRooms v-model="form.hospital_room_id" :floor_id="form.floor_id" size="mini"></hospitalRooms>
              </el-form-item>
          </el-col>
          <el-col :span="12">
              <el-form-item :label="$t('message.class')" prop="classs_id">
                  <classes v-model="form.classs_id" :classs_id="form.classs_id" size="mini"></classes>
              </el-form-item>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
            <el-form-item :label="$t('message.bunk')" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->
        </el-row>
        <!-- end el-row -->
      </el-form>
    </div>
  </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    import form from '@/utils/mixins/form';
    import corpses from '@/components/selects/corps';
    import floors from '@/components/selects/floor';
    import classes from '@/components/selects/class';
    import hospitalRooms from '@/components/selects/hospitalRoom';
    import drawerChild from '@/utils/mixins/drawer-child';

    export default {
        mixins:[form, drawerChild],
        data(){
            return {}
        },
        components:{
          corpses,
          floors,
          classes,
          hospitalRooms,
        },
        created(){},
        computed: {
            ...mapGetters({
                rules: 'bunks/rules',
                model: 'bunks/model',
                columns: 'bunks/columns'
            })
        },
        methods:{
            ...mapActions({
                save: 'bunks/store',
                empty: 'bunks/empty',
            }),
            afterOpened(){
                this.form = JSON.parse( JSON.stringify(this.model) );
            },
            afterClosed(){
                this.empty()
            },
            submit(close = true){
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged()
                                 if (close == true) {
                                    this.close();
                                }
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }
                });
            }
        }
    }
</script>
